 
 
 
:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  
}

.date-range-button {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 8px 16px;
  cursor: pointer;
}

.chart-container {
  position: relative;
  width: 100%;
  height: 400px;
}

.chart-container canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-dropdown-panel {
  top: 100% !important;
}